.infoTable table td {
  padding: 5px;
}
.infoTable table tr td:first-of-type {
  vertical-align: top;
  font-weight: bold;
}
.infoTable table tr:nth-child(even) {
  background-color: #efefef;
}

.hide-actions tr .actions {
  visibility: hidden;
}
.hide-actions tr:hover .actions {
  visibility: visible;
}

.smallText {
  font-size: 0.8rem;
  font-family: "Arial Narrow", Arial, sans-serif;
}